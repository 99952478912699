/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { PrivateSalesExportExcelResponseType } from '../../../../services/PrivateSales/types';
import {
  PrivateSalesFiltersType,
  privateSalesListSlice,
} from '../../../../stores';
import { delay } from '../../../../utils/Helpers';

export const exportPrivateSalesToCSV = async (
  apiData: PrivateSalesExportExcelResponseType,
  filters: PrivateSalesFiltersType | null,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (
    apiData: PrivateSalesExportExcelResponseType,
    filters: PrivateSalesFiltersType | null,
  ) => Promise<string>,
): Promise<void> => {
  dispatch(privateSalesListSlice.actions.setPrivateSalesExportPercentage(20));

  const handledApiData = await worker(apiData, filters);
  dispatch(privateSalesListSlice.actions.setPrivateSalesExportPercentage(60));

  const fileType = 'data:text/csv;charset=utf-8;';
  const fileExtension = '.csv';
  dispatch(privateSalesListSlice.actions.setPrivateSalesExportPercentage(100));
  await delay(100);

  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `vendas-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );

  return;
};

export const handleApiDataPrivateSalesToCSV = (
  apiData: PrivateSalesExportExcelResponseType,
  filters: PrivateSalesFiltersType | null,
) => {
  const auxDrivers: any[] = [];

  let handledApiData = `"Ticket";"Situação";"Placa";"Metodo de pagamento";"Hora registro";"Hora inicial";"Estacionamento";"Motorista";"Valor";\r\n`;

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.map((item, index) => {
    const registerDateTime = formatDateTime(item.registerDateTime);
    const startDateTime = formatDateTime(item.startDateTime);

    let driver = null;

    if (item.driverId && auxDrivers[item.driverId] === undefined) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      auxDrivers[item.driverId] = filters?.drivers.find((auxItem) => {
        return auxItem.id === item.driverId;
      });
    }

    driver = item.driverId && auxDrivers[item.driverId];

    // "Estacionamento";"Motorista";"Valor";\r\n`;
    handledApiData =
      handledApiData +
      `"${item.parkingTicketId}";"${item.situation}";"${item.plate}";"${
        item.paymentType
      }";"${registerDateTime}";"${startDateTime}";"${
        item.privateParkingName
      }";"${driver.value}";"${
        item.price ? item.price.toString().replace('.', ',') : '0'
      }";\r\n`;
  });

  return handledApiData;
};
