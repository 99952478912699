// All user related database operations can be defined here.
import moment from 'moment';
import { api } from '..';
import {
  fetchPrivateSalesFiltersUrl,
  fetchPrivateSalesListUrl,
  fetchPrivateSalesInfoUrl,
  fetchPrivateSalesExportUrl,
} from './constants';
import {
  PrivateSalesFiltersResponseType,
  PrivateSalesListResponseType,
  PrivateSalesInfoResponseType,
  PrivateSalesExportExcelResponseType,
} from './types';

export const fetchPrivateSalesFiltersService = async () => {
  return await api
    .get<PrivateSalesFiltersResponseType>(fetchPrivateSalesFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchPrivateSalesListService = async (params: {
  startDateTime: string;
  endDateTime: string;
  plate: string;
  paymentType: string;
  privateParkingId: string;
  driverId: string;
  page: string;
}) => {
  return await api
    .get<PrivateSalesListResponseType>(fetchPrivateSalesListUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchPrivateSalesInfoService = async (params: {
  privateSaleId: string;
}) => {
  return await api
    .get<PrivateSalesInfoResponseType>(
      `${fetchPrivateSalesInfoUrl}/${params.privateSaleId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchPrivateSalesExportExcelService = async (params: {
  startDateTime: string;
  endDateTime: string;
  plate: string;
  paymentType: string;
  privateParkingId: string;
  driverId: string;
}) => {
  return await api
    .get<PrivateSalesExportExcelResponseType>(fetchPrivateSalesExportUrl, {
      params: {
        startDateTime: params.startDateTime,
        endDateTime: params.endDateTime,
        plate: params.plate === '' ? null : params.plate,
        paymentType:
          params.paymentType === 'Todos' || params.paymentType === '0'
            ? null
            : params.paymentType,
        privateParkingId:
          params.privateParkingId === '0' ? null : params.privateParkingId,
        driverId: params.driverId === '0' ? null : params.driverId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
